import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';

const initialState = {
    auth: {
        fname:'',
        lname:'',
        email: '',
        idToken: '',
        localId: '',
        // expiresIn: '',
        // refreshToken: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        console.log(action.payload,'action.payload')
        if(typeof action.payload === 'string'){
            return {
                ...state,
                auth: {
                    email: state.email,
                    idToken: action.payload,
                    localId: state.id,
                    fname: state.fname,
                    lname: state.lname,
                },
                errorMessage: '',
                successMessage: 'Login Successfully Completed',
                showLoading: false,
            };
        }
        return {
            ...state,
            auth: {
                email: action.payload.email,
                idToken: action.payload.token,
                localId: action.payload.id,
                fname: action.payload.fname,
                lname: action.payload.lname,
            },
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
        }
       

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

    
