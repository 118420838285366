import React, { Suspense, lazy, useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import Setting from "./layouts/Setting";
//
import { ThemeContext } from "../context/ThemeContext";
import LoadingScreen from "./components/common/LoadingScreen";
/// Dashboard
const Home = lazy(() => import("./pages/Home.js"));
const Dashboard_Status = lazy(() => import("./pages/HomeCountFilter"));

// Staff
const StaffList = lazy(() => import("./pages/Staff/List"));
const AddStaff = lazy(() => import("./pages/Staff/Add"));
const UpdateStaff = lazy(() => import("./pages/Staff/Update"));
const StaffDetail = lazy(() => import("./pages/Staff/Detail"));

// Candidate
const CandidateDetail = lazy(() => import("./pages/Candidate/List"));
const finalInterviewList = lazy(() =>
  import("./pages/finalInterviewList/List")
);

// Manager
const ManagerList = lazy(() => import("./pages/Manager/List"));
const AddManager = lazy(() => import("./pages/Manager/Add"));
const UpdateManager = lazy(() => import("./pages/Manager/Update"));
const ManagerDetail = lazy(() => import("./pages/Manager/Detail"));


// email
const Email = lazy(() => import("./pages/Emails/index"));
/// Pages

const Error404 = lazy(() => import("./pages/Error404"));

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "dashboard/status", component: Dashboard_Status },

    // Staff
    { url: "Staff", component: StaffList },
    { url: "Staff/add", component: AddStaff },
    { url: "Staff/update/:id", component: UpdateStaff },
    { url: "Staff/detail/:id", component: StaffDetail },
    // manager
    { url: "Manager", component: ManagerList },
    { url: "Manager/add", component: AddManager },
    { url: "Manager/update/:id", component: UpdateManager },
    { url: "Manager/detail/:id", component: ManagerDetail },
    // Email
    { url: "Email", component: Email },


    //  candidate
    { url: "candidate", component: CandidateDetail },
    { url: "finalinterview", component: finalInterviewList },

    { url: "*", component: Error404 },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Suspense fallback={<LoadingScreen />}>
              <Switch>
                {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
