import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import SimpleReactLightbox from "simple-react-lightbox";
import { ToastContainer } from "react-toastify";
import { persistor, store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import ThemeContext from "./context/ThemeContext";

// ccss
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SimpleReactLightbox>
        <BrowserRouter>
          <ThemeContext>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <App />
          </ThemeContext>
        </BrowserRouter>
      </SimpleReactLightbox>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
