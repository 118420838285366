import { format } from 'date-fns';
import {
ADD_GLOBAL_DATE_STATES,
RESET_GLOBAL_DATE_STATES
} from '../actions/DateFilerActions';

const initialState = {
 
    dates: [format(new Date(), "yyyy-MM-dd"),format(new Date(), "yyyy-MM-dd")],
    isFiltered: false,

    
};

export function DateFilterReducer(state = initialState, action) {
    console.log(action,'from red')
    if (action.type === ADD_GLOBAL_DATE_STATES) {
        return {
            ...state,
            dates: action.payload.dates,
            isFiltered:action.payload.isFiltered,
        };
    }
    if (action.type === RESET_GLOBAL_DATE_STATES) {
        console.log(action.payload,'action.payload')
   
        return {
            ...state,
            dates: [format(new Date(), "yyyy-MM-dd"),format(new Date(), "yyyy-MM-dd")] ,
            isFiltered: false,
        };
        }
       

    return state;
}

    
