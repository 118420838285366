import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import {
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  subMonths,
  endOfMonth,
  startOfDay,
  subDays,
  format,
  subWeeks,
  endOfDay,
} from "date-fns";
import Button from "react-multi-date-picker/components/button";
import { useDispatch ,useSelector } from "react-redux";
import { AddDatesAction, ResetDatesAction } from "../../../store/actions/DateFilerActions";

export const DateRangeFillter = () => {
  const date = useSelector((state)=>state.date_filter.dates)
  const [values, setValues] = useState();
  const [customRange, setCustomRange] = useState(false);
  const dispatch = useDispatch();
  function MyPlugin() {
    return (
      <>
        <div className="d-flex flex-column p-1 align-item-center justify-content-around">
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => {
              setValues([format(new Date(), "yyyy-MM-dd"),format(new Date(), "yyyy-MM-dd")]);
              dispatch(
                AddDatesAction({
                  dates: [format(new Date(), "yyyy-MM-dd"),format(new Date(), "yyyy-MM-dd")],
                  isFiltered: true,
                })
              );
            }}
          >
            Today
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => {
              dispatch(
                AddDatesAction({
                  dates: [format(subDays(new Date(), 1), 'yyyy-MM-dd'),format(subDays(new Date(), 1), 'yyyy-MM-dd')],
                  isFiltered: true,
                })
              );
              setValues( [format(subDays(new Date(), 1), 'yyyy-MM-dd'),format(subDays(new Date(), 1), 'yyyy-MM-dd')]);
            }}
          >
            Yesterday
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={handleCurrentWeekClick}
          >
            Current Week
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={getLastSevenDaysRange}
          >
            Last 7 Days
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => getPreviousWeekRange(1)}
          >
            Last Week
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => getPreviousWeekRange(2)}
          >
            Last 2 Weeks
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={getCurrentMonthRange}
          >
            This Month
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => getLastMonthRange(1)}
          >
            Last Month
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => {
              setValues(null)
              setCustomRange(true);
            }}
          >
            Custom Range
          </button>
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={() => {
              dispatch(ResetDatesAction())
              setValues(new Date())
            }}
          >
            Reset
          </button>
        </div>
      </>
    );
  }

  useEffect(()=>{
    setValues(date)
  },[date])

  function handleCurrentWeekClick() {
    const now = new Date();
    const startDate = startOfWeek(now); 
    const endDate = endOfWeek(now);

    setValues([
      format(startDate, "yyyy-MM-dd"),
      format(addDays(endDate, 1), "yyyy-MM-dd"),
      // add 1 day to make it inclusive
    ]);
    // setData([
    //     format(startDate, "yyyy-MM-dd"),
    //   format(addDays(endDate, 1), "yyyy-MM-dd"), // add 1 day to make it inclusive
    // ]);
    // setIsFiltered(true);
    dispatch(
      AddDatesAction({
        dates: [
          format(startDate, "yyyy-MM-dd"),
          format(addDays(endDate, 1), "yyyy-MM-dd"), // add 1 day to make it inclusive
        ],
        isFiltered: true,
      })
    );
  }
  function getCurrentMonthRange() {
    const now = new Date();
    const currentMonthStartDate = startOfMonth(now);
    const currentMonthEndDate = endOfDay(now);
    // setData([format(currentMonthStartDate, "yyyy-MM-dd"), format(currentMonthEndDate, "yyyy-MM-dd")]);
    // setIsFiltered(true);
    setValues([
      format(currentMonthStartDate, "yyyy-MM-dd"),
      format(currentMonthEndDate, "yyyy-MM-dd"),
    ]);
    dispatch(
      AddDatesAction({
        dates: [
          format(currentMonthStartDate, "yyyy-MM-dd"),
          format(currentMonthEndDate, "yyyy-MM-dd"),
        ],
        isFiltered: true,
      })
    );
  }

  function getPreviousWeekRange(weekrange) {
    const now = new Date();
    const previousWeekStartDate = startOfWeek(subWeeks(now, weekrange));
    const previousWeekEndDate = endOfWeek(subWeeks(now, 1));
    // setData([format(addDays(previousWeekStartDate, 1), "yyyy-MM-dd"),  format(addDays(previousWeekEndDate, 1), "yyyy-MM-dd")]);
    // setIsFiltered(true);
    setValues([
      format(addDays(previousWeekStartDate, 1), "yyyy-MM-dd"),
      format(addDays(previousWeekEndDate, 1), "yyyy-MM-dd"),
    ]);
    dispatch(
      AddDatesAction({
        dates: [
          format(addDays(previousWeekStartDate, 1), "yyyy-MM-dd"),
          format(addDays(previousWeekEndDate, 1), "yyyy-MM-dd"),
        ],
        isFiltered: true,
      })
    );
  }
  function getLastMonthRange(monthrange) {
    const now = new Date();
    const lastMonthStartDate = startOfMonth(subMonths(now, monthrange));
    const lastMonthEndDate = endOfMonth(subMonths(now, 1));
    //  setData([format(addDays(lastMonthStartDate, 1), "yyyy-MM-dd"),format(addDays(lastMonthEndDate, 1), "yyyy-MM-dd")]);
    // setIsFiltered(true);
    setValues([
      format(addDays(lastMonthStartDate, 1), "yyyy-MM-dd"),
      format(addDays(lastMonthEndDate, 1), "yyyy-MM-dd"),
    ]);
    dispatch(
      AddDatesAction({
        dates: [
          format(addDays(lastMonthStartDate, 1), "yyyy-MM-dd"),
          format(addDays(lastMonthEndDate, 1), "yyyy-MM-dd"),
        ],
        isFiltered: true,
      })
    );
  }
  function getLastSevenDaysRange() {
    const now = new Date();
    const lastSevenDaysStartDate = startOfDay(subDays(now, 6));
    const lastSevenDaysEndDate = endOfDay(now);
    // setData([format(addDays(lastSevenDaysStartDate, 1), "yyyy-MM-dd"),format(addDays(lastSevenDaysEndDate, 1), "yyyy-MM-dd") ]);
    // setIsFiltered(true);
    setValues([
      format(addDays(lastSevenDaysStartDate, 1), "yyyy-MM-dd"),
      format(addDays(lastSevenDaysEndDate, 1), "yyyy-MM-dd"),
    ]);
    dispatch(
      AddDatesAction({
        dates: [
          format(addDays(lastSevenDaysStartDate, 1), "yyyy-MM-dd"),
          format(addDays(lastSevenDaysEndDate, 1), "yyyy-MM-dd"),
        ],
        isFiltered: true,
      })
    );
  }
  function handleChange(value) {
    console.log(value)
    if(value === undefined){
      return
    }
    const formattedDates = value?.map((date) => date.format("YYYY-MM-DD"));    

    if (customRange && value.length === 2) {
      setValues(value);
      dispatch(AddDatesAction({ dates: formattedDates, isFiltered: true }));
    } else if(!customRange && value.length !== 2) {
      console.log(value);
      setValues(value);
      dispatch(
        AddDatesAction({ dates: value.format("YYYY-MM-DD"), isFiltered: true })
      );
    }

    // setData(value);
    // setIsFiltered(true);
  }

  return (
    <div>
      <DatePicker
        numberOfMonths={2}
        value={values}
        onChange={(e)=>handleChange(e)}
        range
        render={<Button />}
        plugins={[<MyPlugin position="left" />]}
      />
    </div>
  );
};
